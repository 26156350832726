import React from 'react'
import Helmet from 'react-helmet'
import config from '../../data/SiteConfig'
// import styled, { injectGlobal, ThemeProvider } from 'styled-components'

import 'katex/dist/katex.min.css' // KaTeX css file
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
import './css/fontawesome-free-5.7.2-web/css/all.min.css'
import './css/reset-and-variables.css'
import './index.css'

const Layout = ({ children }) => (
  <>
    <Helmet>
      <meta name="description" content={config.siteDescription} />
    </Helmet>
    <div data-theme="levels">
      { children }
    </div>
  </>
)

export default Layout
