
module.exports = {
  info: {
    name: 'Betsyla',
    photo: '/astor_teaching.jpg',
    bio: 'High School CS teacher for almost a decade. Remote developer and all-purpose nerd. I write about learning, technology, and how technology learns.',
    contacts: {
      email: 'jeff@astor.io',
      twitter: 'jastronaut',
      github: 'jastor11',
      rss: '/rss.xml',
    }
  },
  blogPostDir: "content", // The name of directory that contains your posts.
  siteTitle: "Betsyla.com", // Site title.
  subTitle: 'A website for a year in betsys life', 
  siteTitleAlt: "Jeff Astor - Educator, Programmer, and All-Purpose Nerd", // Alternative site title for SEO.
  siteLogo: "/logos/logo-48.png", // Logo used for SEO and manifest.
  siteUrl: "https://betsyla.com", // Domain of your website without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription: "A website for a year in betsys life",
  siteRss: "/rss.xml", // Path to the RSS file.
  // siteFBAppID: "1825356251115265", // FB Application ID for using app insights
  googleAnalyticsID: "UA-135198430-1", // GA tracking ID.
  // disqusShortname: "jeffastor", // Disqus shortname.
  // postDefaultCategoryID: "Tech", // Default category for posts.
  // userName: "Jeff Astor", // Username to display in the author segment.
  // userTwitter: "jastornaut", // Optionally renders "Follow Me" in the UserInfo segment.
  // userLocation: "Los Angeles, CA", // User location to display in the author segment.
  // userAvatar: "https://api.adorable.io/avatars/150/test.png", // User avatar to display in the author segment.
  // userDescription:
  //     "Educator, Programmer, and All-Purpose Nerd.", // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  // userLinks: [
  //
  // ],
  // dateFromFormat: 'MM-DD-YYYY',
  copyright: "Copyright © 2020 JeffAstor.com", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#c62828", // Used for setting manifest and progress theme colors.
  backgroundColor: "#e0e0e0" // Used for setting manifest background color.
}
